<template>
    <requestForm :is-new="false" />
  </template>
  
  <script>
  import requestForm from "./requestForm.vue";
  
  export default {
    components: { requestForm },
  };
  </script>
  
  <style></style>
  